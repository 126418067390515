import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from 'react-bootstrap/CardGroup';
import { Container, Row, Col } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
import Logo from "../../Assets/logo.png";
import Cholo from "../../Assets/Projects/cholo.png";



function ProjectCard() {
  return (
    
    <Container fluid>

    <Row xs={1} md={1} lg={2} className="g-2">
      <Col>  
        <Fade bottom>
          <CardGroup>
            <Card className="project-card-view" style={{ height:'50rem'}}>
              <Card.Body>
                <Card.Img style={{ width: '15rem', height: '15rem' }} variant="top" src={Cholo}/>
                <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Pixel Homies</Card.Title><br />
                <Card.Text style={{ textAlign: "Center", fontSize: "1.3em" }}>Pixel Homies es una colección de 1000 personajes coleccionables inspirados en la cultura mexicana con escenas clásicas de Guadalajara, Jalisco. Cada Pixel Homie fue generado algorítmicamente y cuentan con más de 200 distintas variantes las cuales fueron hechas a mano, píxel por píxel.</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade bottom>
          <CardGroup>
            <Card className="project-card-view" style={{ height:'50rem'}}>
              <Card.Body>
              <Card.Img style={{ width: '15rem', height: '15rem'  }} variant="top" src={Logo}/>
                <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Tokenización Inmobiliaria (Coming soon)</Card.Title><br />
                <Card.Text style={{ textAlign: "Center", fontSize: "1.3em" }}>La tokenización es la conversión de activos en tokens digitales. Mediante este proceso, se pueden comprar, vender y negociar activos digitales en cualquier momento, sin la necesidad de pasar por un intermediario.</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

        <Col>
          <Fade bottom>
            <CardGroup>
              <Card className="project-card-view" style={{ height:'40rem'}}>
                <Card.Body>
                <Card.Img style={{ width: '15rem', height: '15rem'  }} variant="top" src={Logo}/>
                  <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>NFTools (Coming soon)</Card.Title><br />
                  <Card.Text style={{ textAlign: "Center", fontSize: "1.3em" }}>Herramientas para colecciones de NFTs generativas con las cuales puedes randomizar los atributos de tus personajes o arte.</Card.Text>
                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>

        <Col>
          <Fade bottom>
            <CardGroup>
              <Card className="project-card-view" style={{ height:'40rem'}}>
                <Card.Body>
                <Card.Img style={{ width: '15rem', height: '15rem'  }} variant="top" src={Logo}/>
                  <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>DRVOSTA (Coming soon)</Card.Title><br />
                  <Card.Text style={{ textAlign: "Center", fontSize: "1.3em" }}>Pagina Web y Coleccion de NFTs</Card.Text>
                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>
      </Row>
      </Container>
  );
}
export default ProjectCard;
