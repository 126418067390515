import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Fade from 'react-reveal/Fade';
import { DiJavascript1, DiPython, DiReact, DiPhotoshop, DiCodeigniter, DiNetbeans } from "react-icons/di"
import { AiOutlineShoppingCart } from "react-icons/ai";

function HomeCardGroup() {
  return (
    <Row xs={1} md={4} className="g-4">
      <Col>
        <Fade left>
          <CardGroup>
            <Card className="home-card-view" style={{ height: '10rem' }}>
            <Card.Body>
              <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiReact /></Card.Title>
              <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Front End</Card.Text>
            </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade left>
          <CardGroup>
            <Card className="home-card-view"style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiPython /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Back End</Card.Text>
              </Card.Body>
              </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade left>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiNetbeans /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>NFTs</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade left>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiJavascript1 /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Web 3.0</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade right>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><AiOutlineShoppingCart /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>eCommerce</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade right>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiPhotoshop /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Fotografía</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade right>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiCodeigniter /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Corazón</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>

      <Col>
        <Fade right>
          <CardGroup>
          <Card className="home-card-view" style={{ height: '10rem' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: "3.5em", fontWeight: "bold" }}><DiCodeigniter /></Card.Title>
                <Card.Text  style={{ fontSize: "1em", fontWeight: "bold" }}>Salvaje</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>
    </Row>
  );
}
export default HomeCardGroup;