import { React, Suspense } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import HomeCard from "./HomeCard";
import HomeCardGroup from "./HomeCardGroup";
import {AiOutlineTwitter,AiFillInstagram, AiFillLinkedin} from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import Fade from 'react-reveal/Fade';


function Home() {
  return (
    <section>
      <Container fluid className="home-about-section" id="home">
        <Particle />
      <Container className="home-content">
          <h1 className="heading-name"><center><strong>Sabarobe Studios</strong></center></h1>
          <HomeCard />
            <br /><hr /><br />
          <HomeCardGroup />
            <br /><hr /><br />

          <Row>
            <Fade bottom>
              <Col md={12} className="home-about-social">
                <h1 style={{ fontSize: "2.1em", padding: "50px" }}><strong>Síguenos</strong></h1>
                  <ul className="home-about-social-links">
                    <li className="social-icons"><a href="https://twitter.com/sabarobestudios" target="_blank" className="icon-colour home-social-icons"><AiOutlineTwitter /></a></li>
                    <li className="social-icons"><a href="https://www.instagram.com/sabarobestudios/" target="_blank" className="icon-colour home-social-icons"><AiFillInstagram /></a></li>
                    <li className="social-icons"><a href="https://www.linkedin.com/company/sabarobe-studios/" target="_blank" className="icon-colour home-social-icons"><AiFillLinkedin /></a></li>
                    <li className="social-icons"><a href="https://discord.gg/F8N7wBSSn6" target="_blank" rel="noreferrer" className="icon-colour home-social-icons"><FaDiscord /></a></li>
                 </ul>
             </Col>
            </Fade>
        </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
