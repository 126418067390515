import React from "react";
import { Container } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Particle from "../Particle";

function Projects() {

  return (
    <Container fluid className="project-section">
  <Particle />
      <Container>
        <h1 style={{ fontSize: "2.6em", paddingTop: "100px", paddingBottom: "20px" }}><strong>Proyectos</strong></h1>  
        <ProjectCard />
      </Container>
    </Container>
  );
}

export default Projects;
