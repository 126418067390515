import React from "react";
import Particle from "../Particle";
import AboutTeam from "./TeamCard";
import Container from "react-bootstrap/Container";

function Team() {
  
  return (
    <Container fluid className="about-section">
      <Particle />
        <Container>
          <h1 style={{ fontSize: "2.6em", paddingTop: "100px", paddingBottom: "20px" }}><strong>Conoce al equipo</strong></h1>
            <AboutTeam />
        </Container>
    </Container>
  );
}

export default Team;
