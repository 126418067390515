import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from "react-bootstrap/Card";
import Mumf from "../../Assets/Team/Mumf.png";
import Yorch from "../../Assets/Team/Yorch.png";
import Gil from "../../Assets/Team/Gil.png";
import Asdru from "../../Assets/Team/Asdru.png";
import Joe from "../../Assets/Team/Joe.png";
import { FiTwitter } from "react-icons/fi";
import logo from "../../Assets/logo.png";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import Verga from "..//Home/Verga";
import { PresentationControls, Environment, ContactShadows } from '@react-three/drei'

function AboutTeam() {
  return (

    <Container fluid>

      <Row xs={1} md={3} className="g-3">

        <Col>
          <Fade bottom>
            <CardGroup>
              <Card className="team-card-view">
                <Card.Body>
                <Card.Img style={{ width: '15rem' }} variant="top" align="Center" src={Mumf}/>
                    <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>David Villarreal</Card.Title>
                    <Card.Text style={{ textAlign: "Center", fontSize: "1.3em"}}>Full Stack Developer</Card.Text><hr />
                    <a href="https://www.twitter.com/mumfordvs" target="_blank" className="social-icons"><FiTwitter / ></a>    
                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>

        <Col>  
          <Fade bottom>
            <CardGroup>
              <Card className="team-card-view">
                <Card.Body>
                <Card.Img style={{ width: '15rem' }} variant="top" src={Yorch}/>
                    <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Jorge Gómez</Card.Title>
                    <Card.Text style={{ textAlign: "Center", fontSize: "1.3em"}}>Artista Gráfico / Fotografía</Card.Text><hr />
                    <a href="https://twitter.com/Yorchhh" target="_blank" className="social-icons"><FiTwitter / ></a>    

                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>


        <Col>  
          <Fade bottom>
            <CardGroup>
              <Card className="team-card-view">
                <Card.Body>
                <Card.Img style={{ width: '15rem' }} variant="top" src={Gil}/>
                    <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Gilberto García</Card.Title>
                    <Card.Text style={{ textAlign: "Center", fontSize: "1.3em"}}>Full Stack Developer</Card.Text><hr />
                    <a href="https://twitter.com/norindes" target="_blank" className="social-icons"><FiTwitter / ></a>    

                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>


        <Col>  
          <Fade bottom>
            <CardGroup>
              <Card className="team-card-view">
                <Card.Body>
                <Card.Img style={{ width: '15rem' }} variant="top" src={Asdru}/>
                    <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Asdrubal Nuño</Card.Title>
                    <Card.Text style={{ textAlign: "Center", fontSize: "1.3em"}}>Senior Full Stack Developer / DevOps</Card.Text><hr />
                    <a href="https://twitter.com/azdcorp" target="_blank" className="social-icons"><FiTwitter / ></a>    

                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>

        <Col>
          <Fade bottom>
           <div className="team-3d">
            <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 4], fov: 50 }}>
                  <ambientLight intensity={0.5} />
                  <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} />
                    <PresentationControls
                      global
                      config={{ mass: 2, tension: 500 }}
                      snap={{ mass: 4, tension: 1500 }}
                      rotation={[0, 0.3, 0]}
                      polar={[-Math.PI / 3, Math.PI / 3]}
                      azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
                      <Verga rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.25, 0]} scale={.2} />
                    </PresentationControls>
                      <ContactShadows position={[0, -1.4, 0]} opacity={0.75} scale={10} blur={2.5} far={4} />
                      <Environment preset="city" />
              </Canvas>
          </div>
          </Fade>
        </Col>

        <Col>  
          <Fade bottom>
            <CardGroup>
              <Card className="team-card-view">
                <Card.Body>
                <Card.Img style={{ width: '15rem' }} variant="top" src={Joe}/>
                    <Card.Title style={{ textAlign: "Center", fontSize: "2em", fontWeight: "bold" }}>Marlon Jiménez</Card.Title>
                    <Card.Text style={{ textAlign: "Center", fontSize: "1.3em"}}>Project Manager / Social Media</Card.Text><hr />
                    <a href="https://twitter.com/jsabarobe" target="_blank" className="social-icons"><FiTwitter / ></a>    

                </Card.Body>
              </Card>
            </CardGroup>
          </Fade>
        </Col>

      </Row>

    </Container>
  );
}
export default AboutTeam;