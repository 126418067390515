import React, { useRef } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useFrame } from '@react-three/fiber'

/* export default function Verga({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/SS.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-.5, 0, 0]} rotation={[-Math.PI / 1, 0, 1]}>
        <group rotation={[Math.PI / 180, 380, 45]} scale={0.01}>
          <group scale={40}>
          <mesh geometry={nodes.SS.geometry} material={materials.Silver} position={[0, 0, 0]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={50} />
           </group>
          </group>
        </group>
      </group>
  );
}

useGLTF.preload("/SS.glb"); */

export default function Verga(props) {
  const ref = useRef()
  const { nodes, materials } = useGLTF('/SS.glb')
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.x = -Math.PI / 1.75 + Math.cos(t / 4) / 8
    ref.current.rotation.y = Math.sin(t / 4) / 8
    ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10
  })
  
  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh mesh geometry={nodes.SS.geometry} material={materials.Silver} position={[-1, 0, 2.5]} rotation={[Math.PI / 170, 380, 35]} transform occlude scale={50}/>
    </group>
  )
}


useGLTF.preload("/SS.glb");