import { React, Suspense } from 'react'
import Card from "react-bootstrap/Card";
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Fade from 'react-reveal/Fade';
import { Canvas } from "@react-three/fiber";
import Verga from "./Verga";
import { PresentationControls, Environment, ContactShadows } from '@react-three/drei'


function HomeCard() {
  return (
    <Row xs={1} md={2} className="g-4">
      <Col>
        <Fade bottom>
          <div className="home-3d">
          <Suspense fallback={null}>
            <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 4], fov: 50 }}>
                <ambientLight intensity={0.5} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} />
                  <PresentationControls
                    global
                    config={{ mass: 2, tension: 500 }}
                    snap={{ mass: 4, tension: 1500 }}
                    rotation={[0, 0.3, 0]}
                    polar={[-Math.PI / 3, Math.PI / 3]}
                    azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
                    <Verga rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.25, 0]} scale={.2} />
                  </PresentationControls>
                    <ContactShadows position={[0, -1.4, 0]} opacity={0.75} scale={10} blur={2.5} far={4} />
                    <Environment preset="city" />
            </Canvas>
            </Suspense>
          </div>
        </Fade>
      </Col>
      <Col>
        <Fade bottom>
          <CardGroup>
            <Card className="home-card-view">
              <Card.Body>
                <Card.Title style={{ textAlign: "Center", fontSize: "2.6em", fontWeight: "bold" }}>El futuro de internet ya está aquí</Card.Title><br />
                <Card.Text style={{ textAlign: "Center", fontSize: "1.6em"}}>Desarrollamos aplicaciones Web a la medida que responden a la estrategia de marketing, seleccionando la mejor herramienta para cada proyecto y aportando un valor añadido incluyendo tecnología Blockchain, con el objetivo de potenciar los negocios de nuestros clientes en Internet, ya sea con colecciones de NFTs o monetización.</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>
      <Col>
        <Fade bottom>
          <CardGroup>
            <Card className="home-card-view">
              <Card.Body>
                <Card.Title style={{ textAlign: "Center", fontSize: "2.6em", fontWeight: "bold" }}>¿Quienes somos?</Card.Title><br />
                <Card.Text style={{ textAlign: "Center", fontSize: "1.6em"}}>Somos un equipo de desarrolladores y artistas mexicanos interesados en contribuir al ecosistema de la web 3.0, nuestra meta es continuar construyendo y creando comunidad con todos nuestros amigos y seguidores.</Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Fade>
      </Col>
    </Row>
    
  );
}
export default HomeCard;